<template>
  <n-layout
    :content-style="this.isMobile ? 'padding: 8px; max-height: 90vh' : 'padding: 64px; max-height: 93vh'"
    :native-scrollbar="false"
  >
    <n-data-table
      :columns="createColumns(themeVars, getDetails)"
      :data="createData(lessons, userStatsData, totalUsers)"
      :row-class-name="rowClassName"
    />

    <br><br>

    <n-modal v-model:show="showModal">
      <n-card
        style="width: 600px"
        title="Detali testo statistika"
        size="huge"
        role="dialog"
      >
        <template v-if="loadingLesson">
          <n-spin size="large" />
        </template>
        <template v-else v-for="(content_) in currentContent.content" v-bind:key="content_.type">

          <n-divider>{{ content_.title }}</n-divider>
          <div v-if="content_.type == 'md'" v-html="content_.data"></div>
          <div v-if="content_.answers">
            <answersComp
              :style="'pointer-events: none;'"
              :answers="content_.answers"
              :selectedAnswers="content_.answers.map(answer => { return answer.isCorrect ? answer._id : false })"
            />
          </div>
          <template v-if="Object.keys(lessonStats).length == 0">
            <n-spin size="large" />
          </template>
          <template v-else>
            <!-- This v-if is for edge cases for example where there's no question/answer just text in the block-->
            <template v-if="lessonStats[content_._id]">
              <br>
              <n-statistic label="Vidutinis vieno vartotojo neteisingų atsakymų skaičius">
                {{ lessonStats[content_._id].average }}
                <template #prefix><n-icon :color="themeVars.warningColor"><CloseCircleOutline /></n-icon></template>
              </n-statistic>
              <BarChart
                :chartData="{
                  datasets: [{
                    label: 'Kiek vartotojų į klausimą atsakė tiesingai po pasirinkot bandymų kiekio?',
                    data: lessonStats[content_._id].counts,
                  }],
                  labels: lessonStats[content_._id].values,
                }"
                :options="chartOptions"
                class="chart"
              />
              <br><br>
            </template>
          </template>

        </template>
      </n-card>
    </n-modal>

  </n-layout>
</template>
<script>

import jwtInterceptor from '../../shared/jwt.interceptor'
import config from '../../shared/config'

import { defineComponent, onMounted, ref, h } from 'vue'
import { useIsMobile } from '@/utils/composables'
import { useStore } from 'vuex'

import { NProgress, useThemeVars, NButton } from 'naive-ui'

import answersComp from '@/pages/docs/answerComponentRenderer.vue'

import { CloseCircleOutline, } from '@vicons/ionicons5'

import { BarChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const createColumns = (themeVars, getDetails) => {
  return [
    {
      title: 'Modulis / poteme',
      key: 'title',
      className: 'title'
    },
    {
      title: 'Pabaigę vartotojai',
      key: 'finished'
    },
    {
      title: 'Pabaigusiųjų %',
      key: 'progressBar',
      render (row) {
        if (row.hasStats) {
          return h(
            NProgress,
            {
              type: 'line',
              percentage: row.finished > 0 ? Math.round((row.finished / row.total) * 100) : 0,
              processing: true,
              color: themeVars.successColor
            }
          )
        }
      }
    },
    {
      title: 'Kita',
      key: 'details',
      render (row) {
        if (row.title.toLowerCase().includes('testas')) {
          return h(
            NButton,
            {
              size: 'small',
              onClick: () => getDetails(row)
            },
            { default: () => '📉' }
          )
        }
      }
    },
  ]
}

const createData = (lessons, userStatsData, totalUsers) => {

  // 1. flatten the menu structure to array of items
  const getData = (lessons) => lessons.flatMap((lesson) => {
    return lesson.children ? [lesson, ...lesson.children] : lesson
  })
  let data = getData(lessons)

  // 2. refactor lessons to data table and add stats
  const toRow = (lesson, userStatsData) => {
    const statsFromUsers = userStatsData.filter(lesson_ => lesson_._id == lesson.path)[0]
    const finishedUsers = statsFromUsers ? statsFromUsers.count : 0
    const hasStats = (lesson.children && lesson.children.length > 0) ? false : true
    return {
      path: lesson.path,
      title: lesson.label,
      finished: hasStats ? finishedUsers: '' ,
      total: totalUsers,
      hasStats: hasStats,
      isRoot: (lesson.children && lesson.children.length > 0) || lesson.label == 'ĮVADAS',
    }
  }
  data = data.map(lesson => toRow(lesson, userStatsData, totalUsers))

  return data
}

export default defineComponent({
  name: 'server_stats',
  components: {
    answersComp,
    BarChart,
    CloseCircleOutline,
  },
  setup() {
    const store = useStore()
    const userStatsData = ref([])
    const totalUsers = ref()
    const lessons = ref(store.getters['menu/getMenu'])

    const showModal = ref(false)
    const selectedRow = ref({})
    const currentContent = ref({content: []})
    const loadingLesson = ref(false)
    const lessonStats = ref({})

    onMounted(() => {
      jwtInterceptor.get(config.backend_server + "statistics/getLessonStats", {}).then(response => {
        if (response && response.status === 200) {
          userStatsData.value = response.data
        } else {
          console.log('statsLessons/getLessonStats: something did not work')
        }
      })
      jwtInterceptor.get(config.backend_server + "statistics/getTotalActiveUsers", {}).then(response => {
        if (response && response.status === 200) {
          totalUsers.value = response.data
        } else {
          console.log('statsLessons/getTotalActiveUsers: something did not work')
        }
      })

    })

    const rowClassName = (row) => {
      if (row.isRoot) {
        return 'isRoot'
      }
      return 'isChild'
    }

    const chartOptions = ref({
      loading: true,
      responsive: true,
      plugins: {
        legend: {
          // position: 'top',
          display: false,
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: 'Vartotojų kiekis'
          },
          ticks: {
              precision: 0
          }
        },
        x: {
          title: {
            display: true,
            text: 'Bandymų kiekis (iki teisingo atsakymo)'
          }
        }
      }
    })

    const getDetails = (row) => {

      // first get lesson data
      loadingLesson.value = true
      console.log(row)
      showModal.value = true
      selectedRow.value = row
      store.dispatch('lessons/getLesson', row.path).then(resp => {
        console.log(resp.data)
        currentContent.value = resp.data
        loadingLesson.value = false
      })

      // second get answer stats
      lessonStats.value = {}
      jwtInterceptor.get(config.backend_server + "statistics/getTestStats", { params: {lessonId: row.path }}).then(response => {
        if (response && response.status === 200) {

          // refactor data
          var data = {}
          for (const question_id in response.data) {

            const data_ = response.data[question_id]
            const counts = {}

            for (const num of data_) {
              counts[num] = counts[num] ? counts[num] + 1 : 1;
            }

            data[question_id] = {}
            data[question_id].values = Object.keys(counts)
            data[question_id].counts = Object.values(counts)

            // add average
            const average = arr => arr.reduce((a,b) => a + b, 0) / arr.length
            data[question_id].average = average(response.data[question_id]).toFixed(2);
          }

          console.log(data)

          // set data
          lessonStats.value = data
          chartOptions.value.loading = false
          console.log('test stats', response.data)
        } else {
          console.log('statsLessons/getTestStats: something did not work')
        }
      })

    }

    return {
      userStatsData,
      totalUsers,
      lessons,

      // functions
      createData,
      createColumns,

      themeVars: useThemeVars(),
      isMobile: useIsMobile(),
      rowClassName,
      getDetails,

      showModal,
      selectedRow,
      currentContent,
      loadingLesson,
      lessonStats,
      chartOptions,
    }
  },
  methods: {
  }
})
</script>

<style scoped>
:deep(.isRoot td) {
  font-weight: bold !important;
}
:deep(.isChild .title) {
  padding-left: 35px !important;
}
</style>
